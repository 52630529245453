<div class="dialog p-0">
  <app-dialog-header>
    {{ data.action.text | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <form [formGroup]="taskForm">
      <div class="field-grid overflow-x-hidden" *ngIf="!isLoading">
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.NAME" | translate }}</mat-label>
          <input matInput [formControl]="nameControl" />
          <mat-error
            *ngIf="
              taskForm.controls.name.touched &&
              taskForm.controls.name?.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.DURATION" | translate }}</mat-label>
          <input matInput type="number" [formControl]="durationControl" />
          <mat-error
            *ngIf="
              taskForm.controls.duration.touched &&
              taskForm.controls.duration?.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "LABEL.START-AFTER" | translate }}</mat-label>
          <mat-select
            [formControl]="startAfterControl"
            (selectionChange)="handleStartAfter($event.value)"
          >
            <mat-option
              *ngFor="let task of startAfterOptions$ | async"
              [disabled]="task.disable"
              [value]="task.id"
            >
              <div
                [ngClass]="{
                  'm-l-10': task.subtask
                }"
              >
                #{{ task.id }} - {{ task.name }}
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-full">
          <mat-label>{{ "TASKS.TASK_MANAGER" | translate }}</mat-label>
          <mat-select [formControl]="assigneeControl">
            <mat-option *ngFor="let user of users$ | async" [value]="user.id">
              <div class="image">
                <ng-container *ngIf="!user.primary_image_path">
                  <img
                    *ngIf="user.name"
                    [src]="
                      'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                      user.name
                    "
                    alt=""
                  />

                  <img
                    *ngIf="!user.name"
                    [src]="
                      'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                      user.first_name +
                      user.last_name
                    "
                    alt=""
                  />
                </ng-container>

                <img
                  *ngIf="user.primary_image_path"
                  [src]="user.primary_image_path"
                  [alt]="
                    'https://ui-avatars.com/api/?background=fcc458&color=ffffff&name=' +
                    user.first_name +
                    user.last_name
                  "
                />
              </div>
              <ng-container *ngIf="user.name; else twoNamesOption">{{
                user.name
              }}</ng-container>

              <ng-template #twoNamesOption
                >{{ user.first_name }} {{ user.last_name }}</ng-template
              >

              {{ user?.role_type ? "(" + user.role_type + ")" : "" }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              taskForm.controls.assignee_id.touched &&
              taskForm.controls.assignee_id?.errors?.required
            "
          >
            {{ "GENERALS.FIELD-REQUIRED" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button class="btn-primary" (click)="submit()" [disabled]="isLoading">
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
